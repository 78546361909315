<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("agent.play_head") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("agent.play_head_list") }} </v-card-title>

        <v-card-actions>
          <v-row>
            <v-col xs="12" sm="12" md="12">
              <v-card color="warning">
                <v-card-title class="justify-center text-center">
                  <v-row class="flex-column justify-center pa-4 white--text">
                    <span style="margin-bottom: 0.5rem">{{ $t("agent.play_summary_user") }} : {{ name }}</span>
                    <span>({{ dateFrom }} {{ $t("to") }} {{ dateTo }})</span>
                  </v-row>
                </v-card-title>
                <v-divider />
                <v-card-title class="justify-center layout">
                  <span class="white--text">{{ affiliateTotalReport.total_play_all | currency }} ฿</span>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-card-actions>
          <v-row>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <v-row class="flex-column justify-start pa-4 white--text">
                    <span> {{ $t("sport") }} </span>
                    <span class="white--text pt-2">{{ affiliateTotalReport.total_play_sport | currency }} ฿</span>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <v-row class="flex-column justify-start pa-4 white--text">
                    <span> {{ $t("alot") }} </span>
                    <span class="white--text pt-2">{{ affiliateTotalReport.total_play_slot | currency }} ฿</span>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <v-row class="flex-column justify-start pa-4 white--text">
                    <span> {{ $t("casino") }} </span>
                    <span class="white--text pt-2">{{ affiliateTotalReport.total_play_casino | currency }} ฿</span>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pb-0 d-flex align-start row mt-5">
          <span style="padding-left: 15px">
            <date-picker-input ref="startDate" :date="dateFrom" :label="$t('agent.commision_date_from')"
              @setValue="dateFrom = $event" />
          </span>
          <span style="padding-left: 15px">
            <date-picker-input ref="endDate" :date="dateTo" :label="$t('agent.commision_date_to')"
              @setValue="dateTo = $event" />
          </span>
          <v-btn color="primary" @click="searchRef" class="mx-5"> {{ $t('search') }} </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">ค้นหา:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions> -->
        <!-- <v-card-actions class="pt-0">
          <v-data-table
            mobile-breakpoint="0"
            style="width: 100%"
            :headers="headers"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')"
            :options.sync="pagination"
            :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50, 100, 200, 300],
            }"
            :items="members"
          >
          </v-data-table>
        </v-card-actions> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      name: '-',
      dateFrom: moment().subtract(10, 'year').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      breadcrumbsItems: [
        {
          text: this.$t('partner'),
          disabled: false,
          href: '/agent',
        },
      ],
      headers: [
        {
          text: this.$t('user_name'),
          value: 'member',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head2'),
          value: 'deposit',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head3'),
          value: 'withdraw',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head4'),
          value: 'profit',
          align: 'end',
          width: '100px',
          sortable: false,
        },
      ],
      members: [],
      searchData: null,
      dummy: true,
      id: this.$route.params.id,
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      affiliateTotalReport: [],
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // if (cont) await this.getPartnerRef(newValue.page, newValue.itemsPerPage)
        // await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy)
          await this.getPartnerRef(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchData,
          )
        if (cont && this.dummy)
          await this.getPartnerRef(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchData,
          )
      },
      deep: true,
    },
  },
  async created() {
    this.addLogPage()
    this.fetchData()

    await this.$store.dispatch('getPartner', { id: this.id }).then(res => {
      this.name = res.data[0].name
      this.refCount = res.totalRef
    })
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'พันธมิตร',
              url: window.location.href,
              detail: 'รายการ สรุปยอดเล่น',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async fetchData() {
      try {
        this.$store
          .dispatch('dashboardAffiliateTotalPlay', {
            id: this.id,
            time_from: this.formatDateYYYY(this.dateFrom),
            time_to: this.formatDateYYYY(this.dateTo),
          })
          .then(result => {
            let resultData = {
              total_play_all: 0,
              total_play_sport: 0,
              total_play_slot: 0,
              total_play_casino: 0,
            }
            const totalData = result?.data
            resultData.total_play_all = result.all_total
            for (let dt of totalData) {
              if (dt.type_game === 'CASINO') {
                resultData.total_play_casino = dt.total_play
              } else if (dt.type_game === 'SLOT') {
                resultData.total_play_slot = dt.total_play
              } else if (dt.type_game === 'SPORT') {
                resultData.total_play_sport = dt.total_play
              }
            }
            this.affiliateTotalReport = resultData
          })
      } catch (e) { }
    },
    async searchRef() {
      this.dummy = false
      await this.getAffiliateTotalPlayRef(
        1,
        this.pagination.rowsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData,
      )
      this.pagination.page = 1
    },
    async getAffiliateTotalPlayRef(page, row, time_from, time_to) {
      this.loading = true
      this.members = []
      try {
        var rs = await this.$store.dispatch('dashboardAffiliateTotalPlay', {
          id: this.id,
          page: page,
          rows: row,
          ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
          ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      // this.pagination.totalItems = rs.count
      // rs.data.forEach(element => {
      //   this.members.push({
      //     id: element.id,
      //     member: element.member ? element.member.phone : null,
      //     deposit: element.deposit,
      //     withdraw: element.withdraw,
      //     profit: element.profit,
      //     // date: moment(element.created_at).format('YY-MM-DD HH:mm'),
      //     // wallet_auto: '0.00',
      //     // wallet_commission: '0.00',
      //     // username: element.member_game.username,
      //     // password: '',
      //     // date: moment(element.created_at).format('YY-MM-DD HH:mm'),
      //   })
      // })

      let resultData = {
        total_play_all: 0,
        total_play_sport: 0,
        total_play_slot: 0,
        total_play_casino: 0,
      }
      const totalData = rs?.data
      resultData.total_play_all = rs.all_total
      for (let dt of totalData) {
        if (dt.type_game === 'CASINO') {
          resultData.total_play_casino = dt.total_play
        } else if (dt.type_game === 'SLOT') {
          resultData.total_play_slot = dt.total_play
        } else if (dt.type_game === 'SPORT') {
          resultData.total_play_sport = dt.total_play
        }
      }
      this.affiliateTotalReport = resultData
      this.loading = false
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getPartnerRef(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
